import { FC, ReactNode } from 'react'

import { useRecoilState } from 'recoil'
import { Drawer } from 'rsuite'

import { toBeViewedOrderAtom } from '../../../atoms/orders'

export interface ViewOrderModalProps {
  children: ReactNode
}

export const ViewOrderDrawer: FC<ViewOrderModalProps> = ({ children }) => {
  const [toBeViewed, setToBeViewed] = useRecoilState(toBeViewedOrderAtom)

  return (
    <Drawer
      open={!!toBeViewed}
      onClose={() => setToBeViewed(null)}
      placement="right"
      size="full"
    >
      <Drawer.Header>
        <Drawer.Title>جزئیات سفارش</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>{children}</Drawer.Body>
    </Drawer>
  )
}
