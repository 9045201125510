import React from 'react'

import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { CustomProvider } from 'rsuite'
import { Toaster } from 'react-hot-toast'

import { routes } from './routes'
import { ReqWrapper } from './components/req-wrapper'

import './styles/index.scss'
import 'rsuite/styles/index.less'
import './styles/_rsuite.less'

const zoomRoot = ReactDOM.createRoot(
  document.getElementById('goods-manager') as HTMLDivElement,
)

zoomRoot.render(
  <React.StrictMode>
    <RecoilRoot>
      <CustomProvider theme="dark">
        <ReqWrapper>
          <Toaster />
          <RouterProvider router={routes} />
        </ReqWrapper>
      </CustomProvider>
    </RecoilRoot>
  </React.StrictMode>,
)
