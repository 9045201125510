import { FormEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { Button, Input } from 'rsuite'
import { userInfoAtom } from '../../atoms/globals'

import { endpoints } from '../../constants/api-endpoints'
import { regExes } from '../../constants/regexes'
import { storageKeys } from '../../constants/storage-keys'
import { useAction } from '../../hooks/use-action'
import { LoginAction } from '../../types/actions/login'
import { Role } from '../../types/user'
import { Storage } from '../../utils/storage'
import { Transpile } from '../../utils/transpile'

export const Login = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const setUserInfo = useSetRecoilState(userInfoAtom)

  const redirectTo = location.search?.replace('?from=', '') || '/'

  const [phone, setPhone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { act, isLoading } = useAction<
    LoginAction.Data,
    LoginAction.Response,
    LoginAction.Result
  >(endpoints.login)

  const isFormValid = !!phone && !!password && regExes.phone.test(phone)

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()

    if (isFormValid) {
      void act(
        {
          phone,
          password,
        },
        response => {
          const { token } = response

          Storage.set(token, storageKeys.authToken)
          Storage.set(response.name, storageKeys.userName)
          Storage.set(response.status, storageKeys.userRole)
          Storage.set(response.id.toString(), storageKeys.userId)

          setUserInfo({
            name: response.name,
            role: response.status,
            token: response.token,
            id: response.id,
          })

          navigate(redirectTo)
          return Transpile.user(response)
        },
      )
    }
  }

  useEffect(() => {
    const token = Storage.get(storageKeys.authToken)
    const name = Storage.get(storageKeys.userName)
    const role = Storage.get(storageKeys.userRole) as Role | null
    const id = Storage.get(storageKeys.userId)

    if (!token || !name || !role || !id) {
      localStorage.clear()
      return
    }

    setUserInfo({ name, role, token, id: +id })
  }, [])

  return (
    <div className="auth-page">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <Input
            placeholder="شماره همراه"
            autoFocus
            value={phone}
            onInput={evt => setPhone(evt.currentTarget.value)}
          />

          <Input
            placeholder="رمز عبور"
            value={password}
            type="password"
            onInput={evt => setPassword(evt.currentTarget.value)}
          />

          <Button
            size="lg"
            appearance="primary"
            loading={isLoading}
            type="submit"
            disabled={!isFormValid}
          >
            ورود به پنل
          </Button>
        </form>
      </div>
    </div>
  )
}
