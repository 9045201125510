import { FC, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { Button, Input, Stack } from 'rsuite'
import { docsAtom } from '../../../atoms/docs'

import { Form } from '../../../components/form'
import { endpoints } from '../../../constants/api-endpoints'
import { useAction } from '../../../hooks/use-action'
import { CreateDocAction } from '../../../types/actions/create-docs'
import { Transpile } from '../../../utils/transpile'

export const AddNewDoc: FC = () => {
  const [file, setFile] = useState<File | null>(null)
  const [description, setDescription] = useState('')
  const [key, setKey] = useState(0)
  const setDocs = useSetRecoilState(docsAtom)

  const resetForm = () => {
    setKey(c => c + 1)
    setFile(null)
    setDescription('')
  }

  const { act, isLoading } = useAction<
    FormData,
    CreateDocAction.Response,
    CreateDocAction.Result
  >(endpoints.addDoc)

  const isFormValid = !!file && !!description

  const handleSubmitForm = () => {
    if (isFormValid) {
      const formData = new FormData()
      formData.append('photo', file)
      formData.append('text', description)

      void act(formData, Transpile.doc, doc => {
        setDocs(current => [doc, ...current])
        resetForm()
      })
    }
  }

  return (
    <Form onSubmit={handleSubmitForm} key={key}>
      <Stack spacing={10}>
        <Stack.Item grow={1}>
          <Input
            placeholder="توضیحات"
            value={description}
            onInput={evt => setDescription(evt.currentTarget.value)}
          />
        </Stack.Item>

        <Input
          type="file"
          accept="image/*"
          onInput={evt => setFile(evt.currentTarget.files?.[0] || null)}
        />

        <Button
          appearance="primary"
          type="submit"
          disabled={!isFormValid}
          loading={isLoading}
        >
          ثبت
        </Button>
      </Stack>
    </Form>
  )
}
