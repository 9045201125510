import axios from 'axios'

import toast, { ToastOptions } from 'react-hot-toast'

import { endpoints } from '../constants/api-endpoints'
import { useState } from 'react'

const baseUrl = 'http://abolfazl.maneliapps.ir/api'

const toastOptions: ToastOptions = {
  style: { borderRadius: '10px', background: '#333', color: '#fff' },
  duration: 1200,
}

export const useAction = <Data = object, Response = any, Result = any>(
  url: endpoints,
  defaultIsLoading = false,
) => {
  const [isLoading, setIsLoading] = useState(defaultIsLoading)

  const act = async (
    data?: Data,
    customizeData?: (data: Response) => Result | null,
    callback?: (res: Result) => void,
    customizeError?: (error: any) => string,
  ) => {
    setIsLoading(true)
    try {
      const response = await axios.post(baseUrl + url, data)
      const result = customizeData
        ? customizeData(response?.data?.data)
        : response?.data?.data

      callback?.(result)
    } catch (error: any) {
      const errorMessage = customizeError
        ? customizeError(error)
        : error?.response?.data?.error

      switch (errorMessage) {
        case 'Unauthorised': {
          toast.error('کاربری با این مشخصات وجود ندارد', toastOptions)
          break
        }
        default: {
          toast.error(errorMessage, toastOptions)
        }
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { act, isLoading }
}
