import { FC } from 'react'
import { useSetRecoilState } from 'recoil'

import { Checkbox, Loader } from 'rsuite'

import { docsAtom } from '../../../atoms/docs'
import { endpoints } from '../../../constants/api-endpoints'
import { useAction } from '../../../hooks/use-action'
import { EditDocAction } from '../../../types/actions/edit-doc'
import { Doc } from '../../../types/doc'
import { Transpile } from '../../../utils/transpile'

export interface ToggleDocStateProps {
  id: number
  state: Doc['state']
}

export const ToggleDocState: FC<ToggleDocStateProps> = ({ id, state }) => {
  const setDocs = useSetRecoilState(docsAtom)

  const { act, isLoading } = useAction<
    EditDocAction.Data,
    EditDocAction.Response,
    EditDocAction.Result
  >(endpoints.changeDocState)

  const handleChangeStatus = () => {
    void act({ id_photo: id }, Transpile.doc, result => {
      setDocs(current => {
        const docs: Doc[] = []
        for (const doc of current) {
          if (doc.id === id) {
            docs.push({
              ...doc,
              state: result.state,
            })
          } else {
            docs.push(doc)
          }
        }
        return docs
      })
    })
  }

  return isLoading ? (
    <Loader className="toggle-state-loader" />
  ) : (
    <Checkbox
      onCheckboxClick={handleChangeStatus}
      checked={state === 'خوانده شده'}
    />
  )
}
