import axios from 'axios'
import { storageKeys } from '../constants/storage-keys'
import { Storage } from '../utils/storage'

export const useAxios = (): void => {
  axios.interceptors.request.use(
    req => {
      const { url } = req
      if (url?.includes('login')) {
        return req
      }
      const token = Storage.get(storageKeys.authToken)
      if (!token) {
        window.location.href = '/login'
        return req
      }
      if (req?.headers) {
        req.headers.Authorization = `Bearer ${token}`
      } else {
        req.headers = {
          Authorization: `Bearer ${token}`,
        }
      }
      return req
    },
    async err => {
      return await Promise.reject(err)
    },
  )
}
