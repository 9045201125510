import { atom } from 'recoil'

import { atomKeys } from '../constants/atom-keys'
import { Doc } from '../types/doc'

export const docsAtom = atom<Doc[]>({
  key: atomKeys.docs,
  default: [],
})

export const toBeDeletedDocAtom = atom<number | null>({
  key: atomKeys.toBeDeletedDoc,
  default: null,
})
