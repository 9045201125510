import { FC } from 'react'

import { AppWrapper } from '../../components/app-wrapper'

import {
  AddSubProduct,
  SubProductLists,
  EditSubProduct,
  DeleteSubProduct,
} from './components'

export const SubProduct: FC = () => {
  return (
    <AppWrapper activeTab="sub-products" className="sub-products-page">
      <AddSubProduct />
      <SubProductLists />
      <EditSubProduct />
      <DeleteSubProduct />
    </AppWrapper>
  )
}
