import { FC } from 'react'

import { AppWrapper } from '../../components/app-wrapper'

import { AddNewDoc, DocsList, DeleteDoc } from './components'

export const Docs: FC = () => {
  return (
    <AppWrapper activeTab="docs" className="docs-page">
      <AddNewDoc />
      <DocsList />
      <DeleteDoc />
    </AppWrapper>
  )
}
