import { FC, useEffect, useState } from 'react'

import { Button, Input, SelectPicker, Stack } from 'rsuite'

import { Form } from '../../../components/form'
import { SELECT_SUB_PRODUCT_STATES } from '../../../constants/sub-product-state'
import { useAction } from '../../../hooks/use-action'
import { endpoints } from '../../../constants/api-endpoints'
import { GetProductsAction } from '../../../types/actions/get-products'
import { CreateSubProductAction } from '../../../types/actions/create-sub-product'
import { Transpile } from '../../../utils/transpile'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { subProductsAtom } from '../../../atoms/sub-product'
import { productsSelectDataAtom } from '../../../atoms/product'

export const AddSubProduct: FC = () => {
  const [products, setProducts] = useRecoilState(productsSelectDataAtom)
  const setSubProducts = useSetRecoilState(subProductsAtom)
  const [key, setKey] = useState(0)
  const [count, setCount] = useState('')

  const initialFormData: Partial<CreateSubProductAction.Data> = {
    product_status_item: SELECT_SUB_PRODUCT_STATES[1].value,
  }

  const [formData, setFormData] =
    useState<Partial<CreateSubProductAction.Data>>(initialFormData)

  const resetForm = () => {
    setKey(c => c + 1)
    setFormData(initialFormData)
  }

  const { act: actGetProducts, isLoading: isLoadingGetProducts } = useAction<
    GetProductsAction.Data,
    GetProductsAction.Response,
    GetProductsAction.Result
  >(endpoints.getProducts, true)

  const { act, isLoading } = useAction<
    CreateSubProductAction.Data,
    CreateSubProductAction.Response,
    CreateSubProductAction.Result
  >(endpoints.addSubProduct)

  const isFormValid = !!formData.product_id && !!formData.product_status_item

  const handleSubmit = () => {
    setFormData(current => ({
      ...current,
      number_item: parseFloat(count),
    }))

    const data: Required<CreateSubProductAction.Data> = {
      ...(formData as Omit<CreateSubProductAction.Data, 'number_item'>),
      number_item: parseFloat(count),
    }

    if (isFormValid) {
      void act(data, Transpile.subProduct, subProduct => {
        setSubProducts(current => [subProduct, ...current])
        resetForm()
      })
    }
  }

  useEffect(() => {
    void actGetProducts({ status: 'all' }, response => {
      const tempProducts: typeof products = []
      response.forEach(product => {
        tempProducts.push({
          value: product.id,
          label: product.product_name,
        })
      })
      setProducts(tempProducts.reverse())
      return []
    })
  }, [])

  return (
    <Form onSubmit={handleSubmit} key={key}>
      <Stack spacing={10}>
        <SelectPicker
          data={products}
          label="محصول"
          loading={isLoadingGetProducts}
          cleanable={false}
          placeholder="انتخاب محصول"
          value={formData.product_id}
          onChange={id =>
            setFormData(current =>
              !id
                ? current
                : {
                    ...current,
                    product_id: id,
                  },
            )
          }
        />

        <Input
          placeholder="تعداد"
          value={formData.number_item || undefined}
          onChange={setCount}
        />

        <SelectPicker
          data={SELECT_SUB_PRODUCT_STATES}
          label="وضعیت"
          defaultValue={SELECT_SUB_PRODUCT_STATES[1].value}
          cleanable={false}
          searchable={false}
          onChange={state =>
            setFormData(current =>
              !state
                ? current
                : {
                    ...current,
                    product_status_item: state,
                  },
            )
          }
        />

        <Button
          appearance="primary"
          type="submit"
          disabled={!isFormValid}
          loading={isLoading}
        >
          ثبت
        </Button>
      </Stack>
    </Form>
  )
}
