import { FC, ReactNode, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { userInfoAtom } from '../../atoms/globals'
import { storageKeys } from '../../constants/storage-keys'
import { Role } from '../../types/user'
import { Storage } from '../../utils/storage'

export interface AuthWrapperProps {
  children?: ReactNode
}

export const AuthWrapper: FC<AuthWrapperProps> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const setUserInfo = useSetRecoilState(userInfoAtom)

  const logout = () => {
    localStorage.clear()
    navigate(`/login?from=${location.pathname}`, {
      replace: true,
    })
  }

  const saveUser = () => {
    const token = Storage.get(storageKeys.authToken)
    const name = Storage.get(storageKeys.userName)
    const role = Storage.get(storageKeys.userRole) as Role | null
    const id = Storage.get(storageKeys.userId)

    if (!token || !name || !role || !id) {
      logout()
      return
    }

    setUserInfo({ name, role, token, id: +id })
  }

  useEffect(() => {
    const authToken = Storage.get(storageKeys.authToken)

    if (!authToken) {
      logout()
    } else {
      saveUser()
    }
  }, [location])

  return <>{children}</>
}
