import { FC } from 'react'

import { AppWrapper } from '../../components/app-wrapper'

import { AddNewOrder, OrdersList, ViewOrder } from './components'

export const Order: FC = () => {
  return (
    <AppWrapper activeTab="orders" className="orders-page">
      <AddNewOrder />
      <OrdersList />
      <ViewOrder />
    </AppWrapper>
  )
}
