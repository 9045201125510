export namespace MonthsNS {
  export interface SubObjType {
    short: string
    long: string
    narrow: string
  }

  export interface ObjType {
    gregory: SubObjType[]
    solar: SubObjType[]
    lunar: SubObjType[]
  }
}

export const MONTHS: MonthsNS.ObjType = {
  gregory: [
    { long: 'January', short: 'Jan', narrow: 'J' },
    { long: 'February', short: 'Feb', narrow: 'F' },
    { long: 'March', short: 'Mar', narrow: 'M' },
    { long: 'April', short: 'Apr', narrow: 'A' },
    { long: 'May', short: 'May', narrow: 'M' },
    { long: 'June', short: 'Jun', narrow: 'J' },
    { long: 'July', short: 'Jul', narrow: 'J' },
    { long: 'August', short: 'Aug', narrow: 'A' },
    { long: 'September', short: 'Sep', narrow: 'S' },
    { long: 'October', short: 'Oct', narrow: 'O' },
    { long: 'November', short: 'Nov', narrow: 'N' },
    { long: 'December', short: 'Dec', narrow: 'D' },
  ],

  solar: [
    { long: 'فروردین', short: 'فرو', narrow: 'ف' },
    { long: 'اردیبهشت', short: 'ارد', narrow: 'ا' },
    { long: 'خرداد', short: 'خرد', narrow: 'خ' },
    { long: 'تیر', short: 'تیر', narrow: 'ت' },
    { long: 'مرداد', short: 'مرد', narrow: 'م' },
    { long: 'شهریور', short: 'شهر', narrow: 'ش' },
    { long: 'مهر', short: 'مهر', narrow: 'م' },
    { long: 'آبان', short: 'آبا', narrow: 'آ' },
    { long: 'آذر', short: 'آذر', narrow: 'آ' },
    { long: 'دی', short: 'دی', narrow: 'د' },
    { long: 'بهمن', short: 'بهم', narrow: 'ب' },
    { long: 'اسفند', short: 'اسف', narrow: 'ا' },
  ],

  lunar: [
    { long: 'يناير', short: 'ينا', narrow: 'ي' },
    { long: 'فبراير', short: 'فبر', narrow: 'ف' },
    { long: 'مارس', short: 'مار', narrow: 'م' },
    { long: 'أبريل', short: 'أبر', narrow: 'أ' },
    { long: 'مايو', short: 'ماي', narrow: 'م' },
    { long: 'يونيو', short: 'يون', narrow: 'ي' },
    { long: 'يوليو', short: 'يول', narrow: 'ي' },
    { long: 'أغسطس', short: 'أغس', narrow: 'أ' },
    { long: 'سبتمبر', short: 'سبت', narrow: 'س' },
    { long: 'أكتوبر', short: 'أكت', narrow: 'أ' },
    { long: 'نوفمبر', short: 'نوف', narrow: 'ن' },
    { long: 'ديسمبر', short: 'ديس', narrow: 'د' },
  ],
}
