import { createBrowserRouter } from 'react-router-dom'

import { Order, Product, SubProduct, Users, Docs, Login } from './pages'

export const routes = createBrowserRouter([
  { path: '/', element: <Product /> },
  { path: '/sub-products', element: <SubProduct /> },
  { path: '/orders', element: <Order /> },
  { path: '/users', element: <Users /> },
  { path: '/docs', element: <Docs /> },
  { path: '/login', element: <Login /> },
])
