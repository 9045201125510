import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { Nav } from 'rsuite'
import { userInfoAtom } from '../../atoms/globals'

export interface NavbarProps {
  activeTab: 'products' | 'sub-products' | 'orders' | 'users' | 'docs'
}

export const Navbar: FC<NavbarProps> = ({ activeTab }) => {
  const userInfo = useRecoilValue(userInfoAtom)

  return (
    <div className="navbar">
      <Nav appearance="tabs" justified>
        <Nav.Item as="span" active={activeTab === 'products'}>
          <Link to="/">ثبت محصول</Link>
        </Nav.Item>

        <Nav.Item as="span" active={activeTab === 'sub-products'}>
          <Link to="/sub-products">ثبت زیر محصول</Link>
        </Nav.Item>

        {userInfo?.role === 'admin' && (
          <>
            <Nav.Item as="span" active={activeTab === 'orders'}>
              <Link to="/orders">سفارشات</Link>
            </Nav.Item>

            <Nav.Item as="span" active={activeTab === 'docs'}>
              <Link to="/docs">فاکتورها</Link>
            </Nav.Item>

            <Nav.Item as="span" active={activeTab === 'users'}>
              <Link to="/users">کاربران</Link>
            </Nav.Item>
          </>
        )}
      </Nav>
    </div>
  )
}
