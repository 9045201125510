import { findIndex } from 'lodash'
import { FC, useEffect } from 'react'

import { useRecoilState, useSetRecoilState } from 'recoil'
import { Button, Table } from 'rsuite'

import { docsAtom, toBeDeletedDocAtom } from '../../../atoms/docs'
import { ListLoader } from '../../../components/list-loader'
import { endpoints } from '../../../constants/api-endpoints'
import { useAction } from '../../../hooks/use-action'
import { GetDocsAction } from '../../../types/actions/get-docs'
import { Doc } from '../../../types/doc'
import { formatDate } from '../../../utils/format-date'
import { getImagePath } from '../../../utils/get-image-path'
import { Transpile } from '../../../utils/transpile'
import { ToggleDocState } from './toggle-doc-state'

const { Column, HeaderCell, Cell } = Table

export const DocsList: FC = () => {
  const [data, setData] = useRecoilState(docsAtom)
  const setToBeDeletedDoc = useSetRecoilState(toBeDeletedDocAtom)

  const { act, isLoading } = useAction<
    {},
    GetDocsAction.Response,
    GetDocsAction.Result
  >(endpoints.getDocs, true)

  useEffect(() => {
    void act({}, docs => docs.map(Transpile.doc), setData)
  }, [])

  const handleOnDelete = ({ id }: Doc) => {
    setToBeDeletedDoc(id)
  }

  return (
    <Table
      bordered
      cellBordered
      data={data}
      wordWrap="break-word"
      autoHeight
      affixHeader
      loading={isLoading}
      renderLoading={() => <ListLoader isRefetching={data.length > 0} />}
    >
      <Column>
        <HeaderCell>ردیف</HeaderCell>
        <Cell>
          {row =>
            data.length -
            ((findIndex(data, data => row.id === data.id) || 0) + 1) +
            1
          }
        </Cell>
      </Column>

      <Column flexGrow={1}>
        <HeaderCell>توضیحات</HeaderCell>
        <Cell dataKey="description" />
      </Column>

      <Column>
        <HeaderCell>ثبت توسط</HeaderCell>
        <Cell dataKey="owner.name" />
      </Column>

      <Column width={140}>
        <HeaderCell>تاریخ</HeaderCell>
        <Cell>{({ createdAt }) => formatDate(createdAt)}</Cell>
      </Column>

      <Column width={75} align="center">
        <HeaderCell>خوانده‌شده</HeaderCell>
        <Cell align="center">
          {({ state, id }) => <ToggleDocState id={id} state={state} />}
        </Cell>
      </Column>

      <Column>
        <HeaderCell align="center">پیش‌نمایش</HeaderCell>
        <Cell>
          {({ imagePath }) => (
            <a
              className="preview-image"
              href={getImagePath(imagePath)}
              target="_blank"
              rel="noreferrer"
            >
              <img src={getImagePath(imagePath)} alt="image" />
            </a>
          )}
        </Cell>
      </Column>

      <Column width={65}>
        <HeaderCell>عملیات</HeaderCell>

        <Cell>
          {rowData => (
            <Button
              color="red"
              appearance="ghost"
              size="xs"
              onClick={() => handleOnDelete(rowData as Doc)}
            >
              حذف
            </Button>
          )}
        </Cell>
      </Column>
    </Table>
  )
}
