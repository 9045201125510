import { findIndex } from 'lodash'

import { MONTHS } from '../../constants/dates/months'
import { WEEKS } from '../../constants/dates/weeks'

import { DatesNS } from './types'

export class Dates {
  private readonly convert!: DatesNS.Convert
  private readonly formats!: DatesNS.DefaultFormats

  constructor() {
    const { $hh, $ll } = DatesNS.formatTokens
    const slashedFormat = this.getSlashedFormatString()
    const dashedFormat = slashedFormat.replace(/\//g, '-')
    const timeFormat = ` ${$hh}:${$ll}`

    this.convert = new Intl.DateTimeFormat(this.getLocale()).format

    this.formats = {
      full: this.getFullFormatString(),
      fullTimed: this.getFullFormatString(true),

      slashed: slashedFormat,
      slashedTimed: slashedFormat + ` ${timeFormat}`,

      dashed: dashedFormat,
      dashedTimed: dashedFormat + ` ${timeFormat}`,

      timeOnly: timeFormat,
    }
  }

  private readonly getFullFormatString = (timed?: boolean): string => {
    const { $DDD, $MMM, $d, $yyyy, $hh, $ll } = DatesNS.formatTokens

    switch (this.getLocale()) {
      case DatesNS.locales.gregory: {
        return `${$DDD}, ${$MMM} ${$d}, ${$yyyy}`.concat(
          timed ? ` at ${$hh}:${$ll}` : '',
        )
      }
      case DatesNS.locales.solar: {
        return `${$DDD}، ${$d} ${$MMM} ${$yyyy}`.concat(
          timed ? ` ساعت ${$hh}:${$ll}` : '',
        )
      }
      case DatesNS.locales.lunar: {
        return `${$DDD}، ${$d} ${$MMM} ${$yyyy}`.concat(
          timed ? ` في ${$hh}:${$ll}` : '',
        )
      }
    }
  }

  private readonly getSlashedFormatString = (): string => {
    const { $yyyy, $m, $d } = DatesNS.formatTokens

    switch (this.getLocale()) {
      case DatesNS.locales.gregory: {
        return `${$m}/${$d}/${$yyyy}`
      }
      case DatesNS.locales.solar:
      case DatesNS.locales.lunar: {
        return `${$yyyy}/${$m}/${$d}`
      }
    }
  }

  private readonly getLocale = (): DatesNS.locales => {
    const localeName = this.getLocaleName()
    return DatesNS.locales[localeName]
  }

  private readonly getLocaleName = (): DatesNS.Calendars => {
    return 'solar'
  }

  private readonly getDayOfGregoryWeek = (date: Date | string): number => {
    const locale = this.getLocaleName()
    const dayOfWeek = this.toDate(date).toLocaleDateString(
      DatesNS.locales[locale],
      {
        weekday: 'long',
      },
    )

    return findIndex(WEEKS[locale], week => week.long === dayOfWeek)
  }

  private readonly getSplittedTime = (date: Date | string): string[] => {
    return this.toDate(date).toTimeString().split(' ')[0].split(':')
  }

  private readonly getSplittedDate = (date: Date | string): string[] => {
    return this.convert(this.toDate(date))
      .split('/')
      .concat(this.getSplittedTime(date))
  }

  private readonly toGregory = (date: Date | string): DatesNS.Converted => {
    const splittedDate = this.getSplittedDate(date)
    return {
      year: parseInt(splittedDate[2]),
      month: parseInt(splittedDate[0]),
      day: parseInt(splittedDate[1]),
      date: this.getDayOfGregoryWeek(date),
      hour: parseInt(splittedDate[3]),
      minute: parseInt(splittedDate[4]),
      second: parseInt(splittedDate[5]),
    }
  }

  private readonly toSolar = (date: Date | string): DatesNS.Converted => {
    const splittedDate = this.getSplittedDate(date)
    return {
      year: parseInt(splittedDate[0]),
      month: parseInt(splittedDate[1]),
      day: parseInt(splittedDate[2]),
      date: this.getDayOfGregoryWeek(date),
      hour: parseInt(splittedDate[3]),
      minute: parseInt(splittedDate[4]),
      second: parseInt(splittedDate[5]),
    }
  }

  private readonly toUserLocale = (date: Date | string): DatesNS.Converted => {
    const userLocale = this.getLocale()

    switch (userLocale) {
      case DatesNS.locales.gregory: {
        return this.toGregory(date)
      }
      case DatesNS.locales.solar: {
        return this.toSolar(date)
      }
      case DatesNS.locales.lunar: {
        return this.toLunar(date)
      }
    }
  }

  private readonly toLunar = (date: Date | string): DatesNS.Converted => {
    const splittedDate = this.getSplittedDate(date)
    return {
      year: parseInt(splittedDate[2]),
      month: parseInt(splittedDate[1]),
      day: parseInt(splittedDate[0]),
      date: this.getDayOfGregoryWeek(date),
      hour: parseInt(splittedDate[3]),
      minute: parseInt(splittedDate[4]),
      second: parseInt(splittedDate[5]),
    }
  }

  private readonly toDate = (date: string | Date): Date => {
    return typeof date === 'string' ? this.getDate(date) : date
  }

  private readonly subtractOrAdd = (
    date: string | Date,
    count: number,
    duration: DatesNS.Durations,
    add?: boolean,
  ) => {
    date = new Dates().toDate(date)
    if (add) {
      count = count * -1
    }

    switch (duration) {
      case 'seconds': {
        return new Date(date.setSeconds(date.getSeconds() - count))
      }
      case 'minutes': {
        return new Date(date.setMinutes(date.getMinutes() - count))
      }
      case 'hours': {
        return new Date(date.setHours(date.getHours() - count))
      }
      case 'days': {
        return new Date(date.setDate(date.getDate() - count))
      }
      case 'weeks': {
        return new Date(date.setDate(date.getDate() - count * 7))
      }
      case 'months': {
        return new Date(date.setMonth(date.getMonth() - count))
      }
      case 'years': {
        return new Date(date.setFullYear(date.getFullYear() - count))
      }
    }
  }

  getDate(): Date
  getDate(...args: Parameters<DatesNS.Now.Overload1>): Date
  getDate(...args: Parameters<DatesNS.Now.Overload2>): Date
  getDate(...args: any): Date {
    const date = args ? new Date(...(<[]>args)) : new Date()
    return date
  }

  format: DatesNS.Format = (
    dateToFormat = this.getDate(),
    format = this.formats.full,
  ): string => {
    // prettier-ignore
    const {
      $yyyy, $yy, $mm, $m, $MMM, $MM, $M, $dd, $d, $DDD, $DD, $D, $hh, $h, $ll, $l, $ss, $s
    } = DatesNS.formatTokens

    const { year, day, month, date, hour, minute, second } =
      this.toUserLocale(dateToFormat)

    format = typeof format === 'string' ? format : format(this.formats)
    const locale = this.getLocaleName()
    const months = MONTHS[locale]
    const weeks = WEEKS[locale]
    const replace = (key: DatesNS.formatTokens, value: string) => {
      format = (<string>format).replace(new RegExp(`\\${key}`, 'g'), value)
    }

    replace($yyyy, year.toString())
    replace($yy, year.toString().substring(2, 4))

    replace($mm, month.toString().padStart(2, '0'))
    replace($m, month.toString())
    replace($MMM, months[month - 1].long)
    replace($MM, months[month - 1].short)
    replace($M, months[month - 1].narrow)

    replace($dd, day.toString().padStart(2, '0'))
    replace($d, day.toString())
    replace($DDD, weeks[date].long)
    replace($DD, weeks[date].short)
    replace($D, weeks[date].narrow)

    replace($hh, hour.toString().padStart(2, '0'))
    replace($h, hour.toString())

    replace($ll, minute.toString().padStart(2, '0'))
    replace($l, minute.toString())

    replace($ss, second.toString().padStart(2, '0'))
    replace($s, second.toString())

    return format
  }

  static get now(): Date {
    return new Dates().getDate()
  }

  sub = (date: string | Date, count: number, duration: DatesNS.Durations) => {
    return this.subtractOrAdd(date, count, duration)
  }

  add = (date: string | Date, count: number, duration: DatesNS.Durations) => {
    return this.subtractOrAdd(date, count, duration, true)
  }
}

export { DatesNS }
