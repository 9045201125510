import { Role } from '../types/user'

export const translateRole = (role: Role): string => {
  switch (role) {
    case 'admin':
      return 'مدیر'
    case 'clerk':
      return 'کارمند'
  }
}
