export enum atomKeys {
  toBeEditedProduct = 'to-be-edited-product',
  products = 'products',
  productsSelectData = 'products-select-data',
  subProducts = 'sub-products',
  toBeEditedSubProduct = 'to-be-edited-sub-product',
  toBeDeletedSubProduct = 'to-be-deleted-sub-product',
  orders = 'order',
  toBeViewerOrder = 'to-be-viewed-order',
  viewedOrderDetail = 'viewed-order-detail',
  users = 'users',
  docs = 'docs',
  toast = 'toast',
  toBeDeletedDoc = 'to-be-deleted-doc',
  info = 'info',
}
