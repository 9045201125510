import { atom } from 'recoil'
import { atomKeys } from '../constants/atom-keys'
import { MessageProps } from 'rsuite'
import { Role } from '../types/user'

export interface ToastAtom {
  type: MessageProps['type']
  message: string
}

export const toastAtom = atom<ToastAtom>({
  key: atomKeys.toast,
  default: {
    type: 'error',
    message: '',
  },
})

export interface UserInfoAtom {
  token: string
  name: string
  role: Role
  id: number
}
export const userInfoAtom = atom<UserInfoAtom | null>({
  key: atomKeys.info,
  default: null,
})
