import { FC, useEffect } from 'react'

import { useRecoilState, useSetRecoilState } from 'recoil'
import { Button, Table } from 'rsuite'
import { findIndex } from 'lodash'

import { ordersAtom, toBeViewedOrderAtom } from '../../../atoms/orders'
import { ListLoader } from '../../../components/list-loader'
import { endpoints } from '../../../constants/api-endpoints'
import { useAction } from '../../../hooks/use-action'
import { GetOrdersAction } from '../../../types/actions/get-orders'
import { Order } from '../../../types/order'
import { formatDate } from '../../../utils/format-date'
import { Transpile } from '../../../utils/transpile'

import { ToggleOrderState } from './toggle-order-state'

const { Column, HeaderCell, Cell } = Table

export const OrdersList: FC = () => {
  const [data, setData] = useRecoilState(ordersAtom)
  const toBeViewed = useSetRecoilState(toBeViewedOrderAtom)

  const { act, isLoading } = useAction<
    {},
    GetOrdersAction.Response,
    GetOrdersAction.Result
  >(endpoints.getOrders, true)

  const handleOnView = ({ id }: Order) => {
    toBeViewed(id)
  }

  useEffect(() => {
    void act(
      {},
      orders => orders.map(order => Transpile.order(order)),
      orders => setData(orders.reverse()),
    )
  }, [])

  return (
    <Table
      bordered
      cellBordered
      data={data}
      wordWrap="break-word"
      autoHeight
      affixHeader
      loading={isLoading}
      renderLoading={() => <ListLoader isRefetching={data.length > 0} />}
    >
      <Column>
        <HeaderCell>ردیف</HeaderCell>
        <Cell>
          {row =>
            data.length -
            ((findIndex(data, data => row.id === data.id) || 0) + 1) +
            1
          }
        </Cell>
      </Column>

      <Column>
        <HeaderCell>وضعیت</HeaderCell>
        <Cell dataKey="state" />
      </Column>

      <Column width={50} align="center">
        <HeaderCell>فعال</HeaderCell>
        <Cell align="center">
          {({ seen, id }) => <ToggleOrderState id={id} seen={seen} />}
        </Cell>
      </Column>

      <Column flexGrow={2}>
        <HeaderCell>ثبت توسط</HeaderCell>
        <Cell dataKey="owner.name" />
      </Column>

      <Column width={140}>
        <HeaderCell>تاریخ</HeaderCell>
        <Cell>{({ createdAt }) => formatDate(createdAt)}</Cell>
      </Column>

      <Column>
        <HeaderCell>عملیات</HeaderCell>

        <Cell>
          {rowData => (
            <>
              <Button
                color="cyan"
                appearance="ghost"
                size="xs"
                onClick={() => handleOnView(rowData as Order)}
              >
                نمایش
              </Button>
            </>
          )}
        </Cell>
      </Column>
    </Table>
  )
}
