export const UNITS = [
  'کيلوگرم',
  'گرم',
  'مثقال',
  'بسته',
  'جعبه',
  'جين',
  'شيشه',
  'صندوق',
  'عدد',
  'قوطي',
  'کارتن',
  'کيسه',
  'ورق',
] as const

export const SELECT_UNITS = UNITS.map(unit => ({ value: unit, label: unit }))
