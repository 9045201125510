import { FC, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Button, SelectPicker, Stack, Input } from 'rsuite'
import { usersAtom } from '../../../atoms/users'

import { Form } from '../../../components/form'
import { endpoints } from '../../../constants/api-endpoints'
import { regExes } from '../../../constants/regexes'
import { SELECT_ROLES } from '../../../constants/roles'
import { useAction } from '../../../hooks/use-action'
import { RegisterAction } from '../../../types/actions/register'
import { Role } from '../../../types/user'

export const AddNewUser: FC = () => {
  const setUsers = useSetRecoilState(usersAtom)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [role, setRole] = useState<Role>('clerk')
  const [password, setPassword] = useState('')
  const [key, setKey] = useState(0)

  const resetForm = () => {
    setName('')
    setPhone('')
    setRole('clerk')
    setPassword('')
    setKey(c => c + 1)
  }

  const { act, isLoading } = useAction<
    RegisterAction.Data,
    RegisterAction.Response,
    RegisterAction.Result
  >(endpoints.register)

  const handleSubmitForm = () => {
    const now = new Date().toISOString()

    void act(
      {
        name,
        password,
        phone,
        status: role,
      },
      user => ({
        id: 0,
        createdAt: now,
        updatedAt: now,
        name: user.name,
        phone: user.phone,
        role: user.status,
      }),
      user => {
        setUsers(current => [user, ...current])
        resetForm()
      },
      error =>
        error?.response?.data?.error?.phone?.[0].includes('taken')
          ? 'شماره تکراری است'
          : 'خطایی پیش آمد',
    )
  }

  const isFormValid =
    !!name && !!phone && regExes.phone.test(phone) && !!role && !!password

  return (
    <Form onSubmit={handleSubmitForm} key={key}>
      <Stack spacing={10}>
        <Input
          placeholder="نام و نام خانوادگی"
          value={name}
          onChange={setName}
        />

        <Input placeholder="شماره همراه" value={phone} onChange={setPhone} />

        <SelectPicker
          data={SELECT_ROLES}
          label="نقش"
          cleanable={false}
          searchable={false}
          defaultValue={SELECT_ROLES[1].value}
          value={role}
          onChange={value => setRole(current => value ?? current)}
        />

        <Input placeholder="رمز عبور" value={password} onChange={setPassword} />

        <Button
          appearance="primary"
          type="submit"
          disabled={!isFormValid}
          loading={isLoading}
        >
          ثبت
        </Button>
      </Stack>
    </Form>
  )
}
