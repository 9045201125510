import { atom } from 'recoil'

import { atomKeys } from '../constants/atom-keys'
import { Order } from '../types/order'

import { OrderDetail } from '../types/order-detail'

export const toBeViewedOrderAtom = atom<number | null>({
  key: atomKeys.toBeViewerOrder,
  default: null,
})

export const ordersAtom = atom<Order[]>({
  key: atomKeys.orders,
  default: [],
})

export const viewedOrderDetailAtom = atom<OrderDetail[]>({
  key: atomKeys.viewedOrderDetail,
  default: [],
})
