import { FC } from 'react'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Button, InputNumber, Modal, SelectPicker, Stack } from 'rsuite'

import { productsSelectDataAtom } from '../../../atoms/product'
import {
  subProductsAtom,
  toBeEditedSubProductAtom,
} from '../../../atoms/sub-product'
import { endpoints } from '../../../constants/api-endpoints'
import { SELECT_SUB_PRODUCT_STATES } from '../../../constants/sub-product-state'
import { useAction } from '../../../hooks/use-action'
import { EditSubProductAction } from '../../../types/actions/edit-sub-product'
import { SubProduct, SubProductStates } from '../../../types/sub-product'

export const EditSubProduct: FC = () => {
  const [toBeEdited, setToBeEdited] = useRecoilState(toBeEditedSubProductAtom)
  const products = useRecoilValue(productsSelectDataAtom)
  const setSubProducts = useSetRecoilState(subProductsAtom)

  const { act, isLoading } = useAction<EditSubProductAction.Data>(
    endpoints.editSubProduct,
  )

  const isFormValid =
    !!toBeEdited?.parent &&
    !!toBeEdited.count &&
    !!toBeEdited.unit &&
    !!toBeEdited.state

  const findProductName = (): string => {
    let name = toBeEdited?.name || ''
    if (products.length === 0 || !toBeEdited) {
      return name
    }
    for (const product of products) {
      if (product.value === toBeEdited.parent) {
        name = product.label as string
      }
    }
    return name
  }

  const handleSubmit = () => {
    if (isFormValid) {
      void act(
        {
          id_item: toBeEdited.id,
          number_item: toBeEdited.count,
          product_id: toBeEdited.parent,
          product_status_item: toBeEdited.state,
        },
        undefined,
        () => {
          setSubProducts(currentVal => {
            const subProducts: SubProduct[] = []
            for (const subProduct of currentVal) {
              if (subProduct.id === toBeEdited.id) {
                subProducts.push({
                  ...subProduct,
                  name: findProductName(),
                  parent: toBeEdited.parent,
                  count: toBeEdited.count,
                  state: toBeEdited.state,
                })
              } else {
                subProducts.push(subProduct)
              }
            }
            return subProducts
          })

          setToBeEdited(null)
        },
      )
    }
  }

  return (
    <Modal
      open={!!toBeEdited}
      onClose={() => setToBeEdited(null)}
      className="edit-sub-product-modal"
      size="md"
    >
      <Modal.Header>
        <Modal.Title>ویرایش زیر محصول</Modal.Title>
      </Modal.Header>

      {toBeEdited && (
        <Modal.Body>
          <Stack spacing={10}>
            <SelectPicker
              data={products}
              label="محصول"
              cleanable={false}
              value={toBeEdited.parent}
              placeholder="انتخاب محصول"
              onChange={value =>
                setToBeEdited(currentVal =>
                  value
                    ? {
                        ...currentVal!,
                        parent: value,
                      }
                    : currentVal,
                )
              }
            />

            <InputNumber
              placeholder="تعداد"
              value={toBeEdited.count}
              onChange={count =>
                setToBeEdited(currentVal => ({
                  ...currentVal!,
                  count: +count,
                }))
              }
            />

            <SelectPicker
              data={SELECT_SUB_PRODUCT_STATES}
              label="وضعیت"
              defaultValue={SELECT_SUB_PRODUCT_STATES[0].value}
              cleanable={false}
              searchable={false}
              value={toBeEdited.state}
              onChange={(value: SubProductStates | null) =>
                setToBeEdited(currentVal =>
                  value
                    ? {
                        ...currentVal!,
                        state: value,
                      }
                    : currentVal,
                )
              }
            />
          </Stack>
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={handleSubmit}
          disabled={!isFormValid}
          loading={isLoading}
        >
          اعمال تغییرات
        </Button>
        <Button appearance="subtle" onClick={() => setToBeEdited(null)}>
          انصراف
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
