import { FC } from 'react'
import { useSetRecoilState } from 'recoil'

import { Checkbox, Loader } from 'rsuite'

import { ordersAtom } from '../../../atoms/orders'
import { endpoints } from '../../../constants/api-endpoints'
import { useAction } from '../../../hooks/use-action'
import { EditOrderAction } from '../../../types/actions/edit-order'
import { Order } from '../../../types/order'
import { Transpile } from '../../../utils/transpile'

export interface ToggleOrderStateProps {
  id: number
  seen: Order['seen']
}

export const ToggleOrderState: FC<ToggleOrderStateProps> = ({ id, seen }) => {
  const setOrders = useSetRecoilState(ordersAtom)

  const { act, isLoading } = useAction<
    EditOrderAction.Data,
    EditOrderAction.Response,
    EditOrderAction.Result
  >(endpoints.editOrder)

  const handleChangeStatus = () => {
    void act({ id_order: id }, Transpile.order, result => {
      setOrders(current => {
        const orders: Order[] = []
        for (const order of current) {
          if (order.id === id) {
            orders.push({
              ...order,
              seen: result.seen,
            })
          } else {
            orders.push(order)
          }
        }
        return orders
      })
    })
  }

  return isLoading ? (
    <Loader className="toggle-state-loader" />
  ) : (
    <Checkbox
      onCheckboxClick={handleChangeStatus}
      checked={seen === 'خوانده شده'}
    />
  )
}
