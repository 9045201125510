import { FC } from 'react'

import { Button, Modal } from 'rsuite'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { useAction } from '../../../hooks/use-action'
import { endpoints } from '../../../constants/api-endpoints'
import { docsAtom, toBeDeletedDocAtom } from '../../../atoms/docs'
import { DeleteDocAction } from '../../../types/actions/delete-doc'

export const DeleteDoc: FC = () => {
  const setDocs = useSetRecoilState(docsAtom)
  const [toBeDeleted, setToBeDeleted] = useRecoilState(toBeDeletedDocAtom)

  const { act, isLoading } = useAction<DeleteDocAction.Data>(
    endpoints.deleteDoc,
  )

  const handleSubmit = () => {
    if (toBeDeleted) {
      void act({ id_photo: toBeDeleted }, undefined, () => {
        setDocs(current => current.filter(doc => doc.id !== toBeDeleted))
        setToBeDeleted(null)
      })
    }
  }

  return (
    <Modal open={!!toBeDeleted} onClose={() => setToBeDeleted(null)} size="xs">
      <Modal.Header>
        <Modal.Title>حذف فاکتور</Modal.Title>
      </Modal.Header>

      {toBeDeleted && (
        <Modal.Body>
          آیا از حذف فاکتور با آی‌دی {toBeDeleted} مطمئن هستی؟
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button
          appearance="primary"
          color="red"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!toBeDeleted}
        >
          حذف
        </Button>
        <Button appearance="subtle" onClick={() => setToBeDeleted(null)}>
          انصراف
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
