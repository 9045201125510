import { FC, FormEvent, HTMLAttributes, ReactNode } from 'react'

import classNames from 'classnames'

export interface FormProps
  extends Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  onSubmit: () => void
  children?: ReactNode
}

export const Form: FC<FormProps> = ({ onSubmit, children, ...rest }) => {
  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    onSubmit()
  }

  const classes = classNames('form-component', {
    [rest.className ?? '']: !!rest.className,
  })

  return (
    <form className={classes} onSubmit={handleSubmit}>
      {children}
    </form>
  )
}
