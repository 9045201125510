import { FC } from 'react'
import { Loader } from 'rsuite'

export interface ListLoaderProps {
  isRefetching: boolean
}

export const ListLoader: FC<ListLoaderProps> = ({ isRefetching }) => {
  return (
    <div className="list-loader">
      <Loader
        size="md"
        content={`در حال دریافت${isRefetching ? ' مجدد ' : ' '}لیست...`}
      />
    </div>
  )
}
