import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { Button } from 'rsuite'
import { userInfoAtom } from '../../atoms/globals'
import { translateRole } from '../../utils/translate-role'

export const Header: FC = () => {
  const userInfo = useRecoilValue(userInfoAtom)
  const navigate = useNavigate()

  const logout = () => {
    localStorage.clear()
    navigate('/login')
  }

  return (
    <div className="header">
      {userInfo && (
        <span>
          {userInfo.name} ({translateRole(userInfo.role)})
        </span>
      )}

      <Button onClick={logout} size="xs" appearance="subtle" color="violet">
        خروج
      </Button>
    </div>
  )
}
