export namespace DatesNS {
  export interface Duration {
    years?: number
    months?: number
    weeks?: number
    days?: number
    hours?: number
    minutes?: number
    seconds?: number
  }

  export type Durations = keyof Duration

  export interface Converted {
    year: number
    month: number
    day: number
    date: number
    hour: number
    minute: number
    second: number
  }

  export type Convert = (date?: Date | number) => string

  export type Format = (
    date?: Date | string,
    format?: string | ((formats: DefaultFormats) => string),
  ) => string

  export enum locales {
    gregory = 'en-US-u-nu-latn-ca-gregory-hc-h24',
    solar = 'fa-IR-u-nu-latn-ca-persian-hc-h24',
    lunar = 'ar-SA-u-nu-latn-ca-islamic-civil-hc-h24',
  }

  export type Calendars = keyof typeof locales

  export namespace Now {
    export type Overload1 = (value: number | string) => Date
    export type Overload2 = (
      year: number,
      month: number,
      date?: number,
      hours?: number,
      minutes?: number,
      seconds?: number,
      ms?: number,
    ) => Date
  }

  export interface DefaultFormats {
    full: string // Friday, June 1, 2022
    fullTimed: string // Friday, June 1, 2022 at 23:17

    slashed: string // 6/15/2022
    slashedTimed: string // 6/15/2022 23:17

    dashed: string // 1401-05-26
    dashedTimed: string // 1401-05-26 23:17

    timeOnly: string // 23:17
  }

  export enum formatTokens {
    $yyyy = '$yyyy', // 2022 | 2032
    $yy = '$yy', // 22 | 32

    $mm = '$mm', // 01 - 12
    $m = '$m', // 1 - 12
    $MMM = '$MMM', // January - December
    $MM = '$MM', // Jan - Dec
    $M = '$M', // J - D

    $dd = '$dd', // 01 - 31
    $d = '$d', // 1 - 31
    $DDD = '$DDD', // Sunday - Saturday
    $DD = '$DD', // Sun - Sat
    $D = '$D', // S - S

    $hh = '$hh', // 01 - 24
    $h = '$h', // 1 - 24

    $ll = '$ll', // 00 - 59 (minute)
    $l = '$l', // 0 - 59 (minute)

    $ss = '$ss', // 00 - 59
    $s = '$s', // 0 - 59
  }
}
