import { atom } from 'recoil'

import { atomKeys } from '../constants/atom-keys'
import { SubProduct } from '../types/sub-product'

export type ToBeEditedSubProduct = Pick<
  SubProduct,
  'id' | 'count' | 'name' | 'parent' | 'state' | 'unit'
>

export const toBeEditedSubProductAtom = atom<ToBeEditedSubProduct | null>({
  key: atomKeys.toBeEditedSubProduct,
  default: null,
})

export const toBeDeletedSubProductAtom = atom<number | null>({
  key: atomKeys.toBeDeletedSubProduct,
  default: null,
})

export const subProductsAtom = atom<SubProduct[]>({
  key: atomKeys.subProducts,
  default: [],
})
