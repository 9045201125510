export enum endpoints {
  register = '/register',
  login = '/login',
  users = '/listUser',

  addProduct = '/insertProduct',
  editProduct = '/updateProduct',
  getProducts = '/listProduct',

  addSubProduct = '/insertItemProduct',
  getSubProducts = '/listItemProduct',
  deleteSubProduct = '/deleteItemProduct',
  editSubProduct = '/updateItemProduct',

  addOrder = '/insertOrder',
  getOrders = '/listOrder',
  getOrderDetails = '/listOrderList',
  editOrder = '/changeStatusOrder',

  addDoc = '/insertPhoto',
  getDocs = '/listPhoto',
  deleteDoc = '/deletePhoto',
  changeDocState = '/changeStatusPhoto',
}
