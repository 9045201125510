import { FC } from 'react'

import { Button, Modal } from 'rsuite'
import { useRecoilState, useSetRecoilState } from 'recoil'

import {
  subProductsAtom,
  toBeDeletedSubProductAtom,
} from '../../../atoms/sub-product'
import { useAction } from '../../../hooks/use-action'
import { endpoints } from '../../../constants/api-endpoints'
import { DeleteSubProductAction } from '../../../types/actions/delete-sub-product'

export const DeleteSubProduct: FC = () => {
  const setSubProducts = useSetRecoilState(subProductsAtom)
  const [toBeDeleted, setToBeDeleted] = useRecoilState(
    toBeDeletedSubProductAtom,
  )

  const { act, isLoading } = useAction<DeleteSubProductAction.Data>(
    endpoints.deleteSubProduct,
  )

  const handleSubmit = () => {
    if (toBeDeleted) {
      void act({ id_item: toBeDeleted }, undefined, () => {
        setSubProducts(current =>
          current.filter(subProduct => subProduct.id !== toBeDeleted),
        )
        setToBeDeleted(null)
      })
    }
  }

  return (
    <Modal
      open={!!toBeDeleted}
      onClose={() => setToBeDeleted(null)}
      className="edit-sub-product-modal"
      size="xs"
    >
      <Modal.Header>
        <Modal.Title>حذف زیر محصول</Modal.Title>
      </Modal.Header>

      {toBeDeleted && (
        <Modal.Body>
          آیا از حذف زیر محصول با آی‌دی {toBeDeleted} مطمئن هستی؟
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button
          appearance="primary"
          color="red"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!toBeDeleted}
        >
          حذف
        </Button>
        <Button appearance="subtle" onClick={() => setToBeDeleted(null)}>
          انصراف
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
