import { FC, ReactNode } from 'react'

import { useAxios } from '../../hooks/use-axios'

export interface ReqWrapperProps {
  children?: ReactNode
}

export const ReqWrapper: FC<ReqWrapperProps> = ({ children }) => {
  useAxios()

  return <>{children}</>
}
