import { findIndex } from 'lodash'
import { FC, useEffect } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { Button, Table } from 'rsuite'
import {
  subProductsAtom,
  toBeDeletedSubProductAtom,
  toBeEditedSubProductAtom,
} from '../../../atoms/sub-product'
import { ListLoader } from '../../../components/list-loader'
import { endpoints } from '../../../constants/api-endpoints'
import { useAction } from '../../../hooks/use-action'
import { GetSubProductsAction } from '../../../types/actions/get-sub-products'
import { SubProduct } from '../../../types/sub-product'
import { formatDate } from '../../../utils/format-date'
import { Transpile } from '../../../utils/transpile'

const { Column, HeaderCell, Cell } = Table

export const SubProductLists: FC = () => {
  const [data, setData] = useRecoilState(subProductsAtom)
  const setToBeEditedSubProduct = useSetRecoilState(toBeEditedSubProductAtom)
  const setToBeDeletedSubProduct = useSetRecoilState(toBeDeletedSubProductAtom)

  const { act, isLoading } = useAction<
    {},
    GetSubProductsAction.Response,
    GetSubProductsAction.Result
  >(endpoints.getSubProducts, true)

  const handleOnEdit = (args: SubProduct) => {
    setToBeEditedSubProduct(args)
  }

  const handleOnDelete = ({ id }: SubProduct) => {
    setToBeDeletedSubProduct(id)
  }

  useEffect(() => {
    void act(
      {},
      subProducts =>
        subProducts.map(subProduct => Transpile.subProduct(subProduct)),
      subProducts => setData(subProducts.reverse()),
    )
  }, [])

  return (
    <Table
      bordered
      cellBordered
      data={data}
      autoHeight
      affixHeader
      width={1000}
      loading={isLoading}
      renderLoading={() => <ListLoader isRefetching={data.length > 0} />}
    >
      <Column>
        <HeaderCell>ردیف</HeaderCell>
        <Cell>
          {row =>
            data.length -
            ((findIndex(data, data => row.id === data.id) || 0) + 1) +
            1
          }
        </Cell>
      </Column>

      <Column flexGrow={2}>
        <HeaderCell>نام محصول</HeaderCell>
        <Cell dataKey="name" />
      </Column>

      <Column>
        <HeaderCell>تعداد</HeaderCell>
        <Cell dataKey="count" />
      </Column>

      <Column>
        <HeaderCell>واحد</HeaderCell>
        <Cell dataKey="unit" />
      </Column>

      <Column>
        <HeaderCell>وضعیت</HeaderCell>
        <Cell dataKey="state" />
      </Column>

      <Column>
        <HeaderCell>ثبت توسط</HeaderCell>
        <Cell dataKey="owner.name" />
      </Column>

      <Column width={140}>
        <HeaderCell>تاریخ</HeaderCell>
        <Cell>{({ createdAt }) => formatDate(createdAt)}</Cell>
      </Column>

      <Column width={150}>
        <HeaderCell>عملیات</HeaderCell>

        <Cell>
          {rowData => (
            <>
              <Button
                color="yellow"
                style={{ marginLeft: 6 }}
                appearance="ghost"
                size="xs"
                onClick={() => handleOnEdit(rowData as SubProduct)}
              >
                ویرایش
              </Button>

              <Button
                color="red"
                appearance="ghost"
                size="xs"
                onClick={() => handleOnDelete(rowData as SubProduct)}
              >
                حذف
              </Button>
            </>
          )}
        </Cell>
      </Column>
    </Table>
  )
}
