import { FC } from 'react'

import { AppWrapper } from '../../components/app-wrapper'

import { AddNewUser, UsersLists } from './components'

export const Users: FC = () => {
  return (
    <AppWrapper activeTab="users" className="users-page">
      <AddNewUser />
      <UsersLists />
    </AppWrapper>
  )
}
