export namespace WeeksNS {
  export interface SubObjType {
    short: string
    long: string
    narrow: string
  }

  export interface ObjType {
    gregory: SubObjType[]
    solar: SubObjType[]
    lunar: SubObjType[]
  }
}

export const WEEKS: WeeksNS.ObjType = {
  gregory: [
    { long: 'Sunday', short: 'Sun', narrow: 'S' },
    { long: 'Monday', short: 'Mon', narrow: 'M' },
    { long: 'Tuesday', short: 'Tue', narrow: 'T' },
    { long: 'Wednesday', short: 'Wed', narrow: 'W' },
    { long: 'Thursday', short: 'Thu', narrow: 'T' },
    { long: 'Friday', short: 'Fri', narrow: 'F' },
    { long: 'Saturday', short: 'Sat', narrow: 'S' },
  ],

  solar: [
    { long: 'شنبه', short: 'شن', narrow: 'ش' },
    { long: 'یکشنبه', short: 'یک', narrow: 'ی' },
    { long: 'دوشنبه', short: 'دو', narrow: 'د' },
    { long: 'سه‌شنبه', short: 'سه‌', narrow: 'س' },
    { long: 'چهارشنبه', short: 'چه', narrow: 'چ' },
    { long: 'پنجشنبه', short: 'پن', narrow: 'پ' },
    { long: 'جمعه', short: 'جم', narrow: 'ج' },
  ],

  lunar: [
    { long: 'السبت', short: 'سب', narrow: 'س' },
    { long: 'الأحد', short: 'حد', narrow: 'ح' },
    { long: 'الاثنين', short: 'ین', narrow: 'ن' },
    { long: 'الثلاثاء', short: 'ثل', narrow: 'ث' },
    { long: 'الأربعاء', short: 'رب', narrow: 'ر' },
    { long: 'الخميس', short: 'خم', narrow: 'خ' },
    { long: 'الجمعة', short: 'جم', narrow: 'ج' },
  ],
}
