import { FC } from 'react'

import { AppWrapper } from '../../components/app-wrapper'

import { EditProduct, NewProduct, ProductLists } from './components'

export const Product: FC = () => {
  return (
    <AppWrapper activeTab="products" className="products-page">
      <NewProduct />
      <ProductLists />
      <EditProduct />
    </AppWrapper>
  )
}
