import { FC } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { Button, Input, Modal, SelectPicker, Stack, Toggle } from 'rsuite'
import { productsAtom, toBeEditedProductAtom } from '../../../atoms/product'
import { endpoints } from '../../../constants/api-endpoints'
import { SELECT_UNITS } from '../../../constants/units'
import { useAction } from '../../../hooks/use-action'
import { EditProductAction } from '../../../types/actions/edit-product'
import { Product } from '../../../types/product'

export const EditProduct: FC = () => {
  const [toBeEdited, setToBeEdited] = useRecoilState(toBeEditedProductAtom)
  const setProducts = useSetRecoilState(productsAtom)

  const { act, isLoading } = useAction<
    EditProductAction.Data,
    EditProductAction.Response,
    EditProductAction.Result
  >(endpoints.editProduct)

  const isFormValid = !!toBeEdited && !!toBeEdited.name

  const handleSubmit = () => {
    if (isFormValid) {
      void act(
        {
          name: toBeEdited.name,
          product_id: toBeEdited.id,
          product_status:
            toBeEdited.state === 'active' ? 'active' : 'notactive',
          unit_product: toBeEdited.unit,
        },
        undefined,
        () => {
          setProducts(currentVal => {
            const products: Product[] = []

            for (const product of currentVal) {
              if (product.id === toBeEdited.id) {
                products.push({
                  ...product,
                  name: toBeEdited.name,
                  state: toBeEdited.state,
                  unit: toBeEdited.unit,
                })
              } else {
                products.push(product)
              }
            }
            return products
          })

          setToBeEdited(null)
        },
      )
    }
  }

  return (
    <Modal
      open={!!toBeEdited}
      onClose={() => setToBeEdited(null)}
      className="edit-product-modal"
    >
      <Modal.Header>
        <Modal.Title>ویرایش محصول</Modal.Title>
      </Modal.Header>

      {toBeEdited && (
        <Modal.Body>
          <Stack spacing={10}>
            <Input
              id="edit-product-name"
              value={toBeEdited.name}
              onInput={evt =>
                setToBeEdited(currentVal => ({
                  ...currentVal!,
                  name: evt.currentTarget.value,
                }))
              }
            />

            <SelectPicker
              data={SELECT_UNITS}
              label="واحد"
              value={toBeEdited.unit}
              cleanable={false}
              searchable={false}
              onChange={value =>
                setToBeEdited(currentVal => ({
                  ...currentVal!,
                  unit: value || 'کيلوگرم',
                }))
              }
            />
          </Stack>

          <Stack spacing={10}>
            <label htmlFor="edit-product-state">وضعیت محصول:</label>
            <Toggle
              checked={toBeEdited.state === 'active'}
              onChange={isActive =>
                setToBeEdited(currentVal => ({
                  ...currentVal!,
                  state: isActive ? 'active' : 'inactive',
                }))
              }
              id="edit-product-state"
              checkedChildren="فعال"
              unCheckedChildren="غیر فعال"
            />
          </Stack>
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!isFormValid}
        >
          اعمال تغییرات
        </Button>
        <Button appearance="subtle" onClick={() => setToBeEdited(null)}>
          انصراف
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
