import { findIndex } from 'lodash'
import { FC, useEffect } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'
import { Badge, Table } from 'rsuite'
import { userInfoAtom } from '../../../atoms/globals'

import { usersAtom } from '../../../atoms/users'
import { ListLoader } from '../../../components/list-loader'
import { endpoints } from '../../../constants/api-endpoints'
import { useAction } from '../../../hooks/use-action'
import { GetUsersAction } from '../../../types/actions/get-users'
import { formatDate } from '../../../utils/format-date'
import { translateRole } from '../../../utils/translate-role'
import { Transpile } from '../../../utils/transpile'

const { Column, HeaderCell, Cell } = Table

export const UsersLists: FC = () => {
  const [data, setData] = useRecoilState(usersAtom)
  const currentUser = useRecoilValue(userInfoAtom)

  const { act, isLoading } = useAction<
    {},
    GetUsersAction.Response,
    GetUsersAction.Result
  >(endpoints.users, true)

  useEffect(() => {
    void act(
      {},
      users => users.map(Transpile.user),
      users => setData(users.reverse()),
    )
  }, [])

  return (
    <Table
      bordered
      cellBordered
      data={data}
      autoHeight
      affixHeader
      width={1000}
      loading={isLoading}
      renderLoading={() => <ListLoader isRefetching={data.length > 0} />}
    >
      <Column>
        <HeaderCell>ردیف</HeaderCell>
        <Cell>
          {row =>
            data.length -
            ((findIndex(data, data => row.id === data.id) || 0) + 1) +
            1
          }
        </Cell>
      </Column>

      <Column flexGrow={2}>
        <HeaderCell>نام</HeaderCell>
        <Cell>
          {({ name, id }) => (
            <>
              {name}
              {currentUser?.id === id && (
                <Badge
                  content="شما"
                  color="violet"
                  style={{ marginRight: 10 }}
                />
              )}
            </>
          )}
        </Cell>
      </Column>

      <Column>
        <HeaderCell>نقش</HeaderCell>
        <Cell>{({ role }) => translateRole(role)}</Cell>
      </Column>

      <Column width={110}>
        <HeaderCell>شماره</HeaderCell>
        <Cell dataKey="phone" />
      </Column>

      <Column width={140}>
        <HeaderCell>تاریخ</HeaderCell>
        <Cell>{({ createdAt }) => formatDate(createdAt)}</Cell>
      </Column>
    </Table>
  )
}
