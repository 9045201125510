import { atom } from 'recoil'
import { ItemDataType } from 'rsuite/esm/@types/common'

import { atomKeys } from '../constants/atom-keys'
import { Product } from '../types/product'

export type ToBeEditedProduct = Pick<Product, 'id' | 'name' | 'state' | 'unit'>

export const toBeEditedProductAtom = atom<ToBeEditedProduct | null>({
  key: atomKeys.toBeEditedProduct,
  default: null,
})

export const productsAtom = atom<Product[]>({
  key: atomKeys.products,
  default: [],
})

export const productsSelectDataAtom = atom<Array<ItemDataType<number>>>({
  key: atomKeys.productsSelectData,
  default: [],
})
