import { FC, useEffect } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'
import { Table } from 'rsuite'

import {
  toBeViewedOrderAtom,
  viewedOrderDetailAtom,
} from '../../../atoms/orders'
import { ViewOrderDrawer } from '.'
import { formatDate } from '../../../utils/format-date'
import { useAction } from '../../../hooks/use-action'
import { endpoints } from '../../../constants/api-endpoints'
import { ListLoader } from '../../../components/list-loader'
import { GetOrderDetailAction } from '../../../types/actions/get-order-detail'
import { Transpile } from '../../../utils/transpile'
import { findIndex } from 'lodash'

const { Column, HeaderCell, Cell } = Table

export const ViewOrder: FC = () => {
  const toBeViewed = useRecoilValue(toBeViewedOrderAtom)
  const [data, setData] = useRecoilState(viewedOrderDetailAtom)

  const { act, isLoading } = useAction<
    GetOrderDetailAction.Data,
    GetOrderDetailAction.Response,
    GetOrderDetailAction.Result
  >(endpoints.getOrderDetails, true)

  useEffect(() => {
    if (toBeViewed) {
      void act(
        { order_id: toBeViewed },
        orderDetails => orderDetails.map(Transpile.orderDetail),
        orderDetail => {
          setData(orderDetail.reverse())
        },
      )
    }
  }, [toBeViewed])

  return (
    <ViewOrderDrawer>
      <Table
        bordered
        cellBordered
        data={data}
        wordWrap="break-word"
        fillHeight
        loading={isLoading}
        renderLoading={() => <ListLoader isRefetching={data.length > 0} />}
      >
        <Column>
          <HeaderCell>ردیف</HeaderCell>
          <Cell>
            {row =>
              data.length -
              ((findIndex(data, data => row.id === data.id) || 0) + 1) +
              1
            }
          </Cell>
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>نام محصول</HeaderCell>
          <Cell dataKey="product.name" />
        </Column>

        <Column width={110}>
          <HeaderCell>واحد</HeaderCell>
          <Cell>
            {({ count, product: { unit } }) =>
              `${count as number} ${unit as number}`
            }
          </Cell>
        </Column>

        <Column>
          <HeaderCell>وضعیت</HeaderCell>
          <Cell dataKey="product.state" />
        </Column>

        <Column>
          <HeaderCell>ثبت توسط</HeaderCell>
          <Cell dataKey="owner.name" />
        </Column>

        <Column width={140}>
          <HeaderCell>تاریخ</HeaderCell>
          <Cell>{({ createdAt }) => formatDate(createdAt)}</Cell>
        </Column>
      </Table>
    </ViewOrderDrawer>
  )
}
