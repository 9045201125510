import { CreateDocAction } from '../types/actions/create-docs'
import { CreateOrderAction } from '../types/actions/create-order'
import { CreateProductAction } from '../types/actions/create-product'
import { CreateSubProductAction } from '../types/actions/create-sub-product'
import { GetOrderDetailAction } from '../types/actions/get-order-detail'
import { GetUsersAction } from '../types/actions/get-users'
import { OrderDetail } from '../types/order-detail'
import { User } from '../types/user'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Transpile {
  static doc(doc: CreateDocAction.Response): CreateDocAction.Result {
    return {
      id: doc.id,
      createdAt: doc.created_at,
      updatedAt: doc.updated_at,
      description: doc.text,
      imagePath: doc.photo,
      state: doc.status,
      owner: {
        id: doc.user_id,
        name: doc.name,
      },
    }
  }

  static order(order: CreateOrderAction.Response): CreateOrderAction.Result {
    return {
      id: order.id,
      createdAt: order.created_at,
      updatedAt: order.updated_at,
      state: order.order_status,
      seen: order.order_type,
      owner: {
        id: order.user_id,
        name: order.name,
      },
    }
  }

  static product(
    product: CreateProductAction.Response,
  ): CreateProductAction.Result {
    return {
      id: product.id,
      createdAt: product.created_at,
      updatedAt: product.updated_at,
      name: product.product_name,
      state: product.product_status === 'active' ? 'active' : 'inactive',
      unit: product.unit_product,
      owner: {
        id: product.user_id,
        name: product.name,
      },
    }
  }

  static subProduct(
    subProduct: CreateSubProductAction.Response,
  ): CreateSubProductAction.Result {
    return {
      id: subProduct.id,
      createdAt: subProduct.created_at,
      updatedAt: subProduct.updated_at,
      count: subProduct.number_item,
      name: subProduct.product_name,
      parent: subProduct.product_id,
      state: subProduct.product_status_item,
      unit: subProduct.unit_product,
      owner: {
        id: subProduct.user_id,
        name: subProduct.name,
      },
    }
  }

  static orderDetail(
    orderDetail: GetOrderDetailAction.PureResponse,
  ): OrderDetail {
    return {
      id: orderDetail.id,
      createdAt: orderDetail.created_at,
      updatedAt: orderDetail.updated_at,
      count: orderDetail.number_list,
      parent: orderDetail.order_id,
      product: {
        id: orderDetail.product_id,
        name: orderDetail.product_name,
        state: orderDetail.product_status_list,
        unit: orderDetail.unit_product,
      },
      owner: {
        id: orderDetail.user_id,
        name: orderDetail.name,
      },
    }
  }

  static user(user: GetUsersAction.PureResponse): User {
    return {
      id: user.id,
      createdAt: user.created_at,
      updatedAt: user.updated_at,
      name: user.name,
      phone: user.phone.toString(),
      role: user.status,
    }
  }
}
