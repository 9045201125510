import { FC, HTMLAttributes, ReactNode } from 'react'

import classnames from 'classnames'

import { Navbar, NavbarProps } from '../navbar'
import { AuthWrapper } from '../auth-wrapper'
import { Header } from '../header'

export interface AppWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode
  activeTab: NavbarProps['activeTab']
}

export const AppWrapper: FC<AppWrapperProps> = ({
  children,
  activeTab,
  ...rest
}) => {
  const classes = classnames('app-wrapper', {
    [rest.className ?? '']: !!rest.className,
  })

  return (
    <AuthWrapper>
      <div {...rest} className={classes}>
        <Header />

        <Navbar activeTab={activeTab} />

        <div className="page-content">{children}</div>
      </div>
    </AuthWrapper>
  )
}
