import { FC, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { Stack, Button, SelectPicker } from 'rsuite'
import { ordersAtom } from '../../../atoms/orders'

import { Form } from '../../../components/form'
import { endpoints } from '../../../constants/api-endpoints'
import { SELECT_SUB_PRODUCT_STATES } from '../../../constants/sub-product-state'
import { useAction } from '../../../hooks/use-action'
import { CreateOrderAction } from '../../../types/actions/create-order'
import { SubProductStates } from '../../../types/sub-product'
import { Transpile } from '../../../utils/transpile'

export const AddNewOrder: FC = () => {
  const [state, setState] = useState<SubProductStates | null>(null)
  const setOrders = useSetRecoilState(ordersAtom)
  const [key, setKey] = useState(0)

  const { act, isLoading } = useAction<
    CreateOrderAction.Data,
    CreateOrderAction.Response,
    CreateOrderAction.Result
  >(endpoints.addOrder)

  const isFormValid = !!state

  const resetForm = () => {
    setKey(c => c + 1)
    setState(null)
  }

  const handleSubmitForm = () => {
    if (isFormValid) {
      void act({ order_status: state }, Transpile.order, order => {
        setOrders(currentOrders => [order, ...currentOrders])
        resetForm()
      })
    }
  }

  return (
    <Form onSubmit={handleSubmitForm} className="add-new-order-form" key={key}>
      <Stack spacing={10}>
        <SelectPicker
          data={SELECT_SUB_PRODUCT_STATES}
          label="وضعیت سفارش"
          cleanable={false}
          searchable={false}
          placeholder="انتخاب وضعیت"
          onChange={setState}
        />

        <Button
          appearance="primary"
          type="submit"
          disabled={!isFormValid}
          loading={isLoading}
        >
          ثبت
        </Button>
      </Stack>
    </Form>
  )
}
