import { FC, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { Stack, Input, Button, SelectPicker } from 'rsuite'
import { productsAtom } from '../../../atoms/product'

import { Form } from '../../../components/form'
import { endpoints } from '../../../constants/api-endpoints'
import { SELECT_UNITS } from '../../../constants/units'
import { useAction } from '../../../hooks/use-action'
import { CreateProductAction } from '../../../types/actions/create-product'
import { SubProductUnits } from '../../../types/sub-product'
import { Transpile } from '../../../utils/transpile'

export const NewProduct: FC = () => {
  const [name, setName] = useState('')
  const [unit, setUnit] = useState<SubProductUnits>('کيلوگرم')
  const [key, setKey] = useState(0)

  const setProducts = useSetRecoilState(productsAtom)

  const resetForm = () => {
    setKey(c => c + 1)
    setName('')
    setUnit('کيلوگرم')
  }

  const { act, isLoading } = useAction<
    CreateProductAction.Data,
    CreateProductAction.Response,
    CreateProductAction.Result
  >(endpoints.addProduct)

  const isFromValid = !!name

  const handleSubmitForm = () => {
    if (isFromValid) {
      void act(
        { name, unit_product: unit },
        Transpile.product,
        product => {
          resetForm()
          setProducts(current => [product, ...current])
        },
        error =>
          error?.response?.data?.error?.name?.[0]?.includes('taken')
            ? 'نام محصول تکراری است'
            : 'مشکلی پیش آمده',
      )
    }
  }

  return (
    <Form onSubmit={handleSubmitForm} key={key}>
      <Stack spacing={10}>
        <Input
          id="product-name"
          onInput={evt => setName(evt.currentTarget.value)}
          value={name}
          placeholder="نام محصول"
        />

        <SelectPicker
          data={SELECT_UNITS}
          label="واحد"
          defaultValue={SELECT_UNITS[0].value}
          cleanable={false}
          searchable={false}
          onChange={value => setUnit(value || 'کيلوگرم')}
        />

        <Button
          appearance="primary"
          type="submit"
          loading={isLoading}
          disabled={!isFromValid}
        >
          ثبت
        </Button>
      </Stack>
    </Form>
  )
}
