import { storageKeys } from '../constants/storage-keys'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Storage {
  static set(value: string, key: storageKeys) {
    localStorage.setItem(key, value)
  }

  static get(key: storageKeys) {
    return localStorage.getItem(key)
  }
}
